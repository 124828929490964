export default {
  meta_title: `Videos — PennyLane`,
  meta_description: `Sit back and explore quantum machine learning and quantum programming with our curated selection of expert videos.`,
  meta_image: `https://assets.cloud.pennylane.ai/pennylane_website/generic/generic_og.png`,
  title: `Videos`,
  text_1: `Sit back and explore quantum machine learning and quantum programming with our curated selection of expert videos.`,
  cards: [
    {
      title: `John Martinis | Between Two Kets`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `John Martinis is currently a professor of physics at the University of California, Santa Barbara, but you probably know him best for his work on Google's quantum supremacy experiment in 2019!`,
      videoId: `a4ZDP4MLatw`,
    },
    {
      title: `Zoë Holmes | Between Two Kets`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `In her first trip to Canada, we sat down with Zoë Holmes to chat about her fascination with hockey, poutine and — oh yeah, quantum machine learning!`,
      videoId: `3Hc9pq5bsWM`,
    },
    {
      title: `Alba Cervera-Lierta | Between Two Kets`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `Alba is a talented researcher at the Barcelona Supercomputing Center (BSC-CNS), but you'll want to watch this episode to hear about her interesting holiday traditions!`,
      videoId: `Nis9H__SGHc`,
    },
    {
      title: `The 2023 Canadian Quantum Cup | Between Two Kets`,
      subtitle: `Catalina Albornoz (Xanadu)`,
      text: `The Canadian Quantum Cup (CQC) is an event where every Canadian university gets the chance to register a team and compete among the best of the best. We sat down with last year's Canadian Quantum Cup winners to hear how they got to their winning ways!`,
      videoId: `1NAm8WVhFhc`,
    },
    {
      title: `Maria Schuld | Between Two Kets`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `Maria is a researcher here at Xanadu and holds the longest BTK episode at the moment! She's an OG PennyLane founder, a QML powerhouse, and as down-to-earth as anyone.`,
      videoId: `fVlTB2Z9_kc`,
    },
    {
      title: `Marco Cerezo | Between Two Kets`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `Memes, barren plateaus, and pumpkin spice lattes — three key food groups that make a great quantum computing researcher like Marco Cerezo!`,
      videoId: `3oQDF2E7uUw`,
    },
    {
      title: `Use a REAL quantum computer (ft. Amazon Braket Hybrid Jobs)`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `Got code that uses quantum and classical and wondering how to run both on the corresponding hardware? Braket's hybrid jobs has you covered.`,
      videoId: `kKItaYSA3u4`,
    },
    {
      title: `Why old Fortran libraries still rule today`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `Broadcasting is a pretty cool concept that leans heavily on old old old Fortran libraries, but is incredibly important in our ML-everywhere world! Learn more about it in this video.`,
      videoId: `i2LAL7oUnqI`,
    },
    {
      title: `How PennyLane Began | Between Two Kets`,
      subtitle: `Isaac De Vlugt, Josh Izaac, Nathan Killoran, and Maria Schuld (Xanadu)`,
      text: `To celebrate PennyLane turning 5 years old, we sat down with Josh, Nathan, and Maria to reminisce about the early days of PennyLane.`,
      videoId: `CR7kia3cspU`,
    },
    {
      title: `The Quantum Algorithm Tier List`,
      subtitle: `Isaac De Vlugt & Guillermo Alonso`,
      text: `We rank the best quantum algorithms. Are we out of our minds? Find out!`,
      videoId: `TPHlIZ0VoJE`,
    },
    {
      title: `Emiliano Godinez | Between Two Kets`,
      subtitle: `Emiliano Godinez (TUM, IQM) & Isaac De Vlugt (Xanadu)`,
      text: `Welcome to Between Two Kets, the podcast where we get to know the biggest names in the quantum programming community. Our second guest is Emiliano Godinez!`,
      videoId: `-qFbRpZIi9E`,
    },
    {
      title: `Five Steps to Approaching Coding Challenges`,
      subtitle: `Isaac De Vlugt`,
      text: `Smash goals, not keyboards! Coding challenges are a great way to sharpen your quantum programming skills, and here's five steps to approaching them.`,
      videoId: `d_-pRZoc5gU`,
    },
    {
      title: `Quantum programmer blindfold challenge`,
      subtitle: `Isaac De Vlugt`,
      text: `Blind coding... yep. Our finest quantum programmers put their muscle memory on the line in this utterly pointless but necessary video.`,
      videoId: `iULspJtGBoQ`,
    },
    {
      title: `Teleportation is possible... sort of | PennyLane Tutorial`,
      subtitle: `Isaac De Vlugt`,
      text: `Is teleportation possible? … Yes, but it wouldn’t be possible without entanglement! Learn how you can teleport qubits in this PennyLane Tutorial.`,
      videoId: `i0RKEPFkl84`,
    },
    {
      title: `Nathan Killoran | Between Two Kets`,
      subtitle: `Isaac De Vlugt & Nathan Killoran (Xanadu)`,
      text: `Welcome to Between Two Kets, the podcast where we get to know the biggest names in the quantum programming community. Our first guest is Nathan Killoran!`,
      videoId: `jpVi56FrYis`,
    },
    {
      title: `The Haar Measure`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `Learn about the complex but crucial topic of sampling random unitaries.`,
      videoId: `d4tdGeqcEZs`,
    },
    {
      title: `Parameter-shift Rule Derivation — Part 2`,
      subtitle: `Antal Szava`,
      text: `Learn how to derive the parameter-shift rule — a versatile and quantum hardware-compatible differentiation method.`,
      videoId: `TnFIW7cYLFU`,
    },
    {
      title: `Quantum Programming Job Interview Q&A`,
      subtitle: `Guillermo Alonso (Xanadu)`,
      text: `What might you be asked in an interview for a quantum programming position? Here's an example worth a full solution.`,
      videoId: `6fM8FatYWt8`,
    },
    {
      title: `Parameter-shift Rule Derivation — Part 1`,
      subtitle: `Antal Szava`,
      text: `Learn how to derive the parameter-shift rule — a versatile and quantum hardware-compatible differentiation method.`,
      videoId: `crgmwXBogx4`,
    },
    {
      title: `Grover's Algorithm`,
      subtitle: `Guillermo Alonso (Xanadu)`,
      text: `Learn about oracles and Grover's search algorithm.`,
      videoId: `EfUfwVnicP8`,
    },
    {
      title: `QAOA: A different perspective`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `Learn about QAOA in a different way!`,
      videoId: `cMZcA2SQnYQ`,
    },
    {
      title: `Measurements and Quantum Circuits`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `Learn about the various types of measurements that are possible in PennyLane.`,
      videoId: `jNb_2Nd3Pzc`,
    },
    {
      title: `Variational Quantum Eigensolver (VQE)`,
      subtitle: `Alvaro Ballon (Xanadu)`,
      text: `Learn about the ubiquitos quantum algorithm: the variational quantum eigensolver (VQE).`,
      videoId: `qiRtUUZ5s9s`,
    },
    {
      title: `How to solve the QUBO problem`,
      subtitle: `Guillermo Alonso (Xanadu)`,
      text: `Learn how to solve quadratic unconstrained binary optimization (QUBO) problems in PennyLane.`,
      videoId: `LhbDMv3iA9s`,
    },
    {
      title: `Introduction to Quantum Chemistry`,
      subtitle: `Alvaro Ballon (Xanadu)`,
      text: `Learn how to model molecules on quantum circuits and then implement some basic calculations using PennyLane.`,
      videoId: `khC0CCjxB7k`,
    },
    {
      title: `Differentiation on quantum hardware`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `Have you ever wondered how we can differentiate an actual quantum computer? In this video, we look at hardware-compatible differentiation methods.`,
      videoId: `ygzf36vEqkU`,
    },
    {
      title: `Optimizing a quantum circuit with PennyLane`,
      subtitle: `Catalina Albornoz (Xanadu)`,
      text: `Understand one of the most fundamental ingredients used in near-term quantum algorithms and quantum machine learning: optimizing quantum circuits.`,
      videoId: `42aa-Ve5WmI`,
    },
    {
      title: `Introduction to PennyLane devices`,
      subtitle: `Guillermo Alonso (Xanadu)`,
      text: `From simulators to real hardware, there are plenty of devices to access in PennyLane. Learn about some of their key features in this video.`,
      videoId: `pgz5aEla1Yo`,
    },
    {
      title: `My first quantum circuit in PennyLane`,
      subtitle: `Isaac De Vlugt (Xanadu)`,
      text: `Learn about the fundamental concept behind what makes PennyLane tick: QNodes.`,
      videoId: `uCm027_jvZ0`,
    },
    {
      title: `How to install PennyLane`,
      subtitle: `Alvaro Ballon (Xanadu)`,
      text: `Get PennyLane installed on your computer (Windows, MacOS, or Linux) with this step-by-step guide.`,
      videoId: `RDdYapLntbE`,
    },
  ],
}
